import { graphql } from 'react-relay'
import type { Environment } from 'relay-runtime'

import type {
    SignInWithEmailInput,
    signInWithEmailMutationResponse,
} from '__generated__/signInWithEmailMutation.graphql'
import createMutationPromise from 'lib/createMutationPromise'

const signInWithEmailMutation =
    (relayEnv: Environment) =>
    (input: SignInWithEmailInput): Promise<signInWithEmailMutationResponse> =>
        createMutationPromise<signInWithEmailMutationResponse>(relayEnv)({
            mutation: graphql`
                mutation signInWithEmailMutation($input: SignInWithEmailInput!) {
                    signInWithEmail(input: $input) {
                        accessToken
                        viewer {
                            me {
                                id
                            }
                        }
                    }
                }
            `,
            variables: { input },
        })

export default signInWithEmailMutation
