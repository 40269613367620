import React from 'react'

import { Field, Form } from 'formik'
import type { FormikProps } from 'formik'
import { object, string } from 'yup'

import GoogleSSOButton from './GoogleSSOButton'
import { VERIFY_EMAIL_MESSAGE } from './Validation'

import { OrSeparator } from '.'

import { LegacyButton as Button } from 'components/Button'
import { ValidationText } from 'components/Field'
import Icon from 'components/Icon'
import { FormikInputField } from 'components/Input'
import { LegacyLink as Link } from 'components/Link'
import Paragraph from 'components/Paragraph'
import Box from 'components/Primitives/Box'
import Set from 'components/Set'
import { getMessageFromUnknownError } from 'lib/getMessageFromUnknownError'
import type { AppsFlyerPBATrackingFn } from 'lib/tracking/appsFlyerPBAClient'
import type { FinanceadsTrackingFn } from 'lib/tracking/financeAds/types'
import type { ImpactTrackingFn } from 'lib/tracking/impact/types'
import useAppleAuth from 'lib/useAppleAuth'
import { routeMap } from 'shared/routes'
import type { RelayProps } from 'types/relay'

export const PREVIOUSLY_SIGNED_MESSAGE =
    'Previously signed in with an email code? You will need to set a password to continue'

export const VALID_EMAIL_MESSAGE = 'Please enter a valid email address'
export const PROVIDE_PASSWORD_MESSAGE = 'Please provide a password'

export const validationSchema = object().shape({
    email: string().trim().email(VERIFY_EMAIL_MESSAGE).required(VALID_EMAIL_MESSAGE),
    password: string() // Don't validate for password
        .required(PROVIDE_PASSWORD_MESSAGE),
})

type QueryProps = { slug: string }

export interface FormValues {
    email: string
    password: string
}

type AdditionalProps = {
    onSuccess: () => void
    impactTrackingFn: ImpactTrackingFn
    financeadsTrackingFn: FinanceadsTrackingFn
    appsFlyerPBATrackingFn: AppsFlyerPBATrackingFn
    buttonText?: string
} & RelayProps &
    QueryProps

type Props = FormikProps<FormValues> & AdditionalProps

const LoginFormFields = (props: Props) => {
    const {
        relay,
        buttonText,
        impactTrackingFn,
        financeadsTrackingFn,
        appsFlyerPBATrackingFn,
        status,
        setStatus,
        isSubmitting,
        slug,
    } = props

    const errorMessage = status ? status.formError : ''

    useAppleAuth(
        relay,
        setStatus,
        slug,
        false,
        impactTrackingFn,
        financeadsTrackingFn,
        appsFlyerPBATrackingFn,
    )

    const signInWithApple = async () => {
        try {
            // @ts-ignore
            if (AppleID) {
                // @ts-ignore
                await AppleID.auth.signIn()
            }
        } catch (error) {
            setStatus({ formError: getMessageFromUnknownError(error) })
        }
    }

    return (
        <Form>
            <Button
                onClick={signInWithApple}
                type="button"
                color="black"
                isBlock
                my={1}
                style={{ color: 'white' }}
            >
                <Icon icon="RiAppleFill" title="Apple" color="white" mr={1} />
                Continue with Apple
            </Button>
            <GoogleSSOButton
                afterAuth={props.onSuccess}
                page="signin"
                relay={relay}
                impactTrackingFn={impactTrackingFn}
                financeadsTrackingFn={financeadsTrackingFn}
                appsFlyerPBATrackingFn={appsFlyerPBATrackingFn}
            />
            <OrSeparator />
            <Set isBlock isVertical spacing={2}>
                <Set isBlock isVertical marginTop={1}>
                    <Field name="email" placeholder="Email address" component={FormikInputField} />
                    <Field
                        name="password"
                        type="password"
                        placeholder="Password"
                        component={FormikInputField}
                    />
                    <Link url={routeMap.forgotPassword}>Forgot Password</Link>
                    {!!errorMessage && (
                        <ValidationText $color="danger">{errorMessage}</ValidationText>
                    )}
                </Set>
                <Button
                    type="submit"
                    title="login-button"
                    isLoading={isSubmitting && !errorMessage}
                    isBlock
                >
                    {buttonText || 'Login'}
                </Button>
                <Box bg="white" p={2} borderRadius="medium" textAlign="center">
                    <Paragraph>{PREVIOUSLY_SIGNED_MESSAGE}</Paragraph>
                    <Link url={routeMap.forgotPassword}>Set a password</Link>
                </Box>
            </Set>
        </Form>
    )
}

export default LoginFormFields
