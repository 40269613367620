import type { FormikHelpers } from 'formik'
import { Formik } from 'formik'
import { object, string } from 'yup'

import { trackLoginCompleted, trackWafGetTokenFailed } from 'lib/analyticsApi'
import { setAuthToken } from 'lib/cookieAuth'
import { getMessageFromUnknownError } from 'lib/getMessageFromUnknownError'
import type { AppsFlyerPBATrackingFn } from 'lib/tracking/appsFlyerPBAClient'
import { AF_LOGIN } from 'lib/tracking/appsFlyerPBAClient'
import type { FinanceadsTrackingFn } from 'lib/tracking/financeAds/types'
import type { ImpactTrackingFn } from 'lib/tracking/impact/types'
import useSessionId from 'lib/useSession'
import { checkUserIsNotBot } from 'lib/wafHelpers'
import type { FormValues } from 'modules/Auth/LoginFormFields'
import LoginFormFields from 'modules/Auth/LoginFormFields'
import { emailValidation } from 'modules/Auth/Validation'
import CaptchaModal from 'modules/Captcha/CaptchaModal'
import useCaptchaModalFlow from 'modules/Captcha/useCaptchaModalFlow'
import signInWithEmail from 'relay/mutations/signInWithEmail'
import type { RelayProps } from 'types/relay'

export const PREVIOUSLY_SIGNED_MESSAGE =
    'Previously signed in with an email code? You will need to set a password to continue'

export const VALID_EMAIL_MESSAGE = 'Please enter a valid email address'
export const PROVIDE_PASSWORD_MESSAGE = 'Please provide a password'

export const validationSchema = object().shape({
    email: emailValidation,
    password: string() // Don't validate for password
        .required(PROVIDE_PASSWORD_MESSAGE),
})

type QueryProps = { slug: string }

export type { FormValues }

type ComponentProps = QueryProps &
    RelayProps & {
        onSuccess: () => void
        impactTrackingFn: ImpactTrackingFn
        financeadsTrackingFn: FinanceadsTrackingFn
        appsFlyerPBATrackingFn: AppsFlyerPBATrackingFn
        buttonText?: string
    }

type Props = ComponentProps

const LoginForm = (props: Props) => {
    const [modalProps, checkForCaptchaErrorAndTriggerFlow] = useCaptchaModalFlow()
    const {
        relay,
        slug,
        onSuccess,
        buttonText,
        impactTrackingFn,
        financeadsTrackingFn,
        appsFlyerPBATrackingFn,
    } = props
    const sessionId = useSessionId(false) || undefined

    const onSubmit = async (values: FormValues, formHelpers: FormikHelpers<FormValues>) => {
        const { setStatus } = formHelpers
        const { environment } = relay
        const { email, password } = values

        try {
            // Protect this logic from suspected bots
            const onFailBotCheck = () =>
                setStatus({ formError: 'Please wait a few seconds, then try again' })
            const didPassBotCheck = await checkUserIsNotBot(onFailBotCheck)

            if (!didPassBotCheck) {
                trackWafGetTokenFailed('Login')
                return
            }

            const res = await signInWithEmail(environment)({
                email,
                password,
                sessionId,
            })

            setAuthToken(res!.signInWithEmail!.accessToken!)
            trackLoginCompleted('email', email)
            const userId = res!.signInWithEmail!.viewer.me!.id
            appsFlyerPBATrackingFn(environment, AF_LOGIN, { customerUserId: userId })

            if (onSuccess) onSuccess()
        } catch (error) {
            const errorMessage = getMessageFromUnknownError(error)

            const hasTriggeredCaptchaFlow = checkForCaptchaErrorAndTriggerFlow(errorMessage, () =>
                onSubmit(values, formHelpers),
            )
            if (!hasTriggeredCaptchaFlow) {
                setStatus({ formError: errorMessage })
            }
        }
    }

    return (
        <>
            <CaptchaModal
                baseId={modalProps.baseId}
                visible={modalProps.visible}
                confirmCaptchaSuccess={modalProps.confirmCaptchaSuccess}
                cancelCaptchaFlow={modalProps.cancelCaptchaFlow}
            />
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {formikProps => (
                    <LoginFormFields
                        onSuccess={onSuccess}
                        impactTrackingFn={impactTrackingFn}
                        financeadsTrackingFn={financeadsTrackingFn}
                        appsFlyerPBATrackingFn={appsFlyerPBATrackingFn}
                        buttonText={buttonText}
                        relay={relay}
                        slug={slug}
                        {...formikProps}
                    />
                )}
            </Formik>
        </>
    )
}

export default LoginForm
