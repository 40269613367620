import React from 'react'

import type { FormikProps } from 'formik'
import { useRouter } from 'next/router'
import { graphql } from 'react-relay'

import Heading from 'components/Heading'
import { LegacyLink as Link } from 'components/Link'
import Paragraph from 'components/Paragraph'
import appsFlyerPBAClient from 'lib/tracking/appsFlyerPBAClient'
import { useFinanceadsContext } from 'lib/tracking/financeAds/useFinanceadsTracking'
import { useImpactContext } from 'lib/tracking/impact/useImpactTracking'
import { withDataNotLoggedIn } from 'lib/withData'
import { Layout } from 'modules/Auth'
import type { FormValues } from 'modules/Auth/LoginForm'
import LoginForm from 'modules/Auth/LoginForm'
import useLoginRedirect, {
    redirectIfLoginParam,
} from 'modules/SignUpOrLogin/Hooks/useLoginRedirect'
import { routeMap } from 'shared/routes'
import type { DefaultPageProps } from 'types'
import type { RelayProps } from 'types/relay'

type ComponentProps = RelayProps & DefaultPageProps

type Props = ComponentProps & FormikProps<FormValues>

const Login = (props: Props) => {
    const router = useRouter()
    // because Apple/Google SSO can create users on both login and signup,
    // we need to do Impact and FinanceAds tracking on both pages.
    const impactClient = useImpactContext()
    const financeadsClient = useFinanceadsContext()

    useLoginRedirect()

    function afterLogin() {
        const redirectUri = redirectIfLoginParam()

        if (redirectUri) {
            router.push(redirectUri)
        } else {
            router.push(routeMap.profile)
        }
    }

    return (
        <Layout seoProps={{ noindex: true, title: 'Login', titleTemplate: '%s - Finimize' }}>
            <Heading as="h3" fontSize="28px" textAlign="center" mb="8px ">
                Welcome Back
            </Heading>

            <Paragraph textAlign="center" color="grey900">
                Don’t have an account?{' '}
                <Link url={{ pathname: routeMap.signup, query: router.query }}>Sign up</Link>
            </Paragraph>

            <LoginForm
                slug=""
                {...props}
                onSuccess={afterLogin}
                impactTrackingFn={impactClient.track}
                financeadsTrackingFn={financeadsClient.track}
                appsFlyerPBATrackingFn={appsFlyerPBAClient.track}
            />
        </Layout>
    )
}

// Dummy query to get the right relay env passed as a prop
export default withDataNotLoggedIn(Login, {
    query: graphql`
        query LoginQuery {
            viewer {
                id
            }
        }
    `,
})
